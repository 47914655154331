import styled from "styled-components";
export const GeneralContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  align-content: center;
  padding-top: 2em;
  min-height: 330px;

  @media (min-width: 850px) {
    padding-top: 6em;
  }

  @media (min-width: 1600px) {
    margin: 90px 0 90px 0;
  }

  a {
    color: #ff1035;
    padding-top: 1em;
  }
`;
