import Cookies from 'js-cookie';

export const clearLocalStorage = () => {

    const values = ['bill','personName','merchantName','amount','clientName','code'];

    values.forEach((value)=>{
        try {
            if (typeof Storage !== 'undefined') {
                localStorage.removeItem(value);
            }else {
                Cookies.remove(value)
            }
        } catch (error) {}
    })

}

export const getLocalstorage = (name: string) => {
    return localStorage.getItem(name) ?? Cookies.get(name);
}

export const setLocalstorage = (name: string, value: string) => {
    if (typeof Storage !== 'undefined') {
        localStorage.setItem(name, value);
    }else{
        createCookie(name, value, 2);
    }
}


export const createCookie = (name: string, value: string, days = 365) => {
    Cookies.set(name, value, { expires: days, path: '/' });
  };