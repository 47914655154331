import styled from "styled-components";

const GeneralContent = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  text-align: center;
  align-items: center;
  margin-bottom: 30px;

  > div {
    max-width: 305px;
    flex-grow: 1;
    @media (min-width: 700px) {
      max-width: 650px;
    }
  }
  .red {
    color: red;
  }
  .font {
    font-size: 15px;
  }
  > p {
    max-width: 305px;
    flex-grow: 1;
    @media (min-width: 700px) {
      max-width: 400px;
    }
  }
`;

export default GeneralContent;
