import { createMuiTheme } from "@material-ui/core/styles";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#083863",
    },
    common: {
      white: "white",
    },
    secondary: {
      main: "#ff1035",
    },
  },
  spacing: 10,
});

export default theme;
