import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "cleave.js/dist/addons/cleave-phone.co";
import Cleave from "cleave.js/react";
import { Field, FieldProps } from "formik";
import React from "react";
import styled from "styled-components";

export const Icon = styled.i`
  position: absolute;
  left: 1px;
  top: 0;
  height: 100%;
  width: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.7;
  color: rgb(111, 113, 115);
  padding-left: 15px;
  font-size: 15px;
`;
export const InputIconStyled = styled.div`
  position: relative;
`;

const InputStyled = styled(Cleave)`
  border: none;
  border-bottom: 1px solid #d6d6d6;
  padding: 0.5em;
  font-size: 15px;
  color: #5f6061;
  padding-left: 45px;
`;

const InputNumberComponent: React.FC<FieldProps & Props> = ({
  form,
  icon,
  field,
  prefix = "",
  delimiter = ".",
  maxLength = 11,
  ...restProps
}) => {
  return (
    <InputIconStyled>
      <InputStyled
        id={field.name}
        options={{
          numeral: true,
          delimiter,
          numeralDecimalMark: ",",
          numeralPositiveOnly: true,
          numeralDecimalScale: 0,
          prefix,
        }}
        maxLength={maxLength}
        type="tel"
        onFocus={(ev: { target: { select: () => void } }) => ev.target.select()}
        {...restProps}
        {...field}
        onChange={(ev: React.ChangeEvent<any>) => {
          ev.target.value = ev.target.rawValue.replace(/\$|\./g, "") || "";
          field.onChange(ev);
        }}
      />
      <Icon>
        <FontAwesomeIcon icon={icon} />
      </Icon>
    </InputIconStyled>
  );
};

export interface Props {
  name: string;
  prefix?: string;
  delimiter?: string;
  placeholder?: string;
  help?: string;
  icon?: any;
  maxLength?: number;
}

const InputNumber: React.FC<Props> = ({ name, help, ...restProps }) => (
  <>
    <Field name={name} {...restProps} component={InputNumberComponent} />
  </>
);

export default InputNumber;
