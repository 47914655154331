/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/no-unused-expressions */
import React, { useEffect } from "react";
import GeneralContent from "../../layout/UI/GeneralContent";
import styled from "styled-components";
import { Grid, Button, makeStyles } from "@material-ui/core";
import Success from "../../../images/success.png";
import useService from "../../../hooks/useService";
import UseClientPhoto from "../../../service/UseClientPhoto";
import UseUserInfo from "../../../service/UseUserInfo";
import { history } from "../../../helpers/history";
import { putFalseIdentity } from "../../../service/putFalseIdentity";
import { paramsURL } from "../../../helpers/paramsURL";
import { RouteComponentProps } from "react-router";
import { setLocalstorage } from "../../../helpers/setLocalStorage";

export const InformationContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 700px;
  background: #f2f1f1;
  align-items: center;
  justify-content: center;
  padding: 25px;
  color: #6f7173;
  margin-top: 20px;
  .color {
    color: red;
  }
  @media (min-width: 850px) {
    margin-top: 40px;
  }

  @media (min-width: 1600px) {
    margin: 20px 0 20px 0;
  }
`;

const UserData = styled.section`
  padding: 10px;
  background: #d4f4d7;
  text-align: justify;
`;

const UserPhoto = styled.img`
  padding: 15px;
  height: 300px;
  width: 300px;
`;

const Icon = styled.img`
  display: none;

  @media (min-width: 850px) {
    display: flex;
    padding: 10px;
    height: 40px;
    width: 40px;
  }
`;

const Row = styled.section`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Align = styled.section`
  display: flex;
  flex-direction: column-reverse;
  align-items: center;

  @media (min-width: 850px) {
    flex-direction: row;
  }
`;

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1),
  },
}));

const throwBack = () => {
  history.push("/informacion-cliente");
};

interface routeProps {
  clientName: string;
  clientId: string
}

const ClientQuota: React.FC<RouteComponentProps<any, any, routeProps>> = ({ location }) => {

  const [userInfo, getUserInfo] = useService(UseUserInfo);
  const [clientPhoto, getClientPhoto] = useService(UseClientPhoto);

  const handleClick = () => {
    const clientId = paramsURL("id");
    if (clientId !== null && userInfo.status === 'loaded') {
      setLocalstorage("personName", userInfo.payload.person.name);
      setLocalstorage("merchantName", userInfo.payload.person.branchOffice.merchant.name);
      setLocalstorage("branchOfficeName", userInfo.payload.person.branchOffice.name);
      history.push(`/pago/?id=${clientId}`);
    }
  };


  useEffect(() => {
    const clientId = paramsURL("id");
    if (clientId !== null) {
      getClientPhoto({ id: clientId });
      getUserInfo();
    }
  }, [getClientPhoto, getUserInfo]);
  const classes = useStyles();
  return (
    <GeneralContent>
      <InformationContainer>
        <UserData>
          <Row>
            <Icon src={Success} alt="success" />
            <p>
              Hola{" "}
              <b>
                {userInfo.status === "loaded" && userInfo.payload.person.name}
              </b>
              , el cliente que quiere pagar en tu comercio tiene un crédito
              aprobado.
            </p>
          </Row>
        </UserData>
        {clientPhoto.status === "loaded" && (
          <UserPhoto src={`${clientPhoto.payload.image}`} />
        )}

        <Grid container justify="center">
          <Grid item md={8} xs={12}>
            <Align>
              <Button
                className={classes.root}
                variant="contained"
                fullWidth
                size="medium"
                color="secondary"
                onClick={throwBack}
              >
                Regresar
              </Button>

              <Button
                className={classes.root}
                variant="contained"
                fullWidth
                size="medium"
                color="secondary"
                onClick={handleClick}
              >
                Continuar
              </Button>
            </Align>
            <Align>
              <Button
                className={classes.root}
                type="submit"
                variant="contained"
                fullWidth
                size="medium"
                color="secondary"
                onClick={putFalseIdentity}
              >
                No es la persona
              </Button>
            </Align>
          </Grid>
        </Grid>

        <p>
          Se le envió un mensaje a WhatsApp al cliente con toda la información.
        </p>
      </InformationContainer>
    </GeneralContent>
  );
};

export default ClientQuota;
