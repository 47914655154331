import { object, string } from "yup";
import { ThirdStepValue } from "../PaymentView";
//.matches(/^\d{1,25}$/, "Ingrese valor adecuado"),

export default object<ThirdStepValue>({
  bill: string()
    .trim()
    .required("Campo requerido")
    .matches(/^[a-zA-Z0-9]*$/, "Ingrese valor adecuado"),
});
