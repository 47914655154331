import React from "react";
import { Button } from "@material-ui/core";
import styled from "styled-components";
import { GeneralContainer } from "../layout/UI/GeneralContainer";

const NotFoundStyled = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  text-align: center;

  h1 {
    font-size: 100px;
    color: gray;
  }
  p {
    color: gray;
  }
`;

const NotFound: React.FC = () => (
  <GeneralContainer>
    <NotFoundStyled>
      <h1>404</h1>
      <p>Página no encontrada.</p>
      <Button href="/">Volver a Juancho te paga.</Button>
    </NotFoundStyled>
  </GeneralContainer>
);

export default NotFound;
