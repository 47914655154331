import React, { useContext } from "react";
import { RouteComponentProps } from "react-router-dom";
import swal from "sweetalert";
import UserContext from "../../components/UserProvider";
import useService from "../../hooks/useService";
import Cancel from "../../images/logo-juancho-te-presta.png";
import styled from "styled-components";
import GeneralContent from "../layout/UI/GeneralContent";
import { Button } from "@material-ui/core";
import UseCancelPaymentPost from "../../service/UseCancelPaymentPost";

const CancelIcon = styled.div`
  height: 150px;
  width: 150px;
  background-image: url(${Cancel});
  background-repeat: no-repeat;
  background-size: contain;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 25px;
  @media (min-width: 700px) {
    height: 200px;
    width: 200px;
  }
`;

const Display = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #808080;

  .divisor {
    width: 250px;
    height: 1px;
    background: #60606047;
    margin-bottom: 15px;

    @media (min-width: 700px) {
      width: 300px;
    }
  }

  @media (min-width: 700px) {
    padding-top: 15px;
    padding-bottom: 15px;
  }
`;

const CancelPayment: React.FC<RouteComponentProps<Record<"token", string>>> = ({
  match,
}) => {
  const [, putLoan] = useService(UseCancelPaymentPost);
  const userContext = useContext(UserContext);

  const onSubmit = async () => {
    const loan = await putLoan({ token: match.params.token });
    if (loan.status === "loaded") {
      await swal("", "¡La cancelación de su pago ha sido exitosa!", "success", {
        dangerMode: true,
      });
      userContext.logout_without_token();
      window.location.replace("https://juanchotepresta.com/");
    } else {
      await swal(
        "",
        "El tiempo para la cancelación de tu solicitud ha expirado.",
        "error",
        {
          dangerMode: true,
        }
      ).then((value) => {
        window.location.replace("https://juanchotepresta.com/");
      });
    }
  };
  return (
    <GeneralContent>
      <Display>
        <CancelIcon />
        <div className="divisor" />
        <p>
          Si usted <strong>NO</strong> ha solicitado un pago con Juancho Te
          Paga, haga click en “Cancelar solicitud”.
        </p>
        <p>Para mayor información escríbenos a: ayuda@juanchotepresta.com</p>
      </Display>
      <Button color="secondary" onClick={onSubmit}>
        Cancelar solicitud
      </Button>
    </GeneralContent>
  );
};
export default CancelPayment;
