import * as Sentry from "@sentry/browser";

export function initSentry() {
  Sentry.init({
    dsn:
      "https://c922d77128ad425c94399349528c6327@sentry.tools.vertical.com.co/13",
    release: `${process.env.REACT_APP_VERSION}`,
    environment: `${process.env.REACT_APP_ENV}`,
  });
}
