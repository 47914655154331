import swal from 'sweetalert';
import getAuthToken from '../helpers/getAuthToken';
import { history } from '../helpers/history';
import { paramsURL } from '../helpers/paramsURL';

export const putFalseIdentity = async () => {
  const clientId = paramsURL('id');
  const headers = new Headers();
  const API_URL: string =
    process.env.REACT_APP_CMS === undefined
      ? `https://jtpg.testing.vertical.com.co/jtpg/api/client/${clientId}?command=rejectTransactioṇ`
      : `https://${process.env.REACT_APP_CMS}/jtpg/api/client/${clientId}?command=rejectTransactioṇ`;

  headers.append('Content-Type', 'application/json; charset=utf-8');
  const token = getAuthToken();
  if (token) {
    if (clientId !== null) {
      headers.append('Authorization', `Bearer ${token}`);
      await fetch(API_URL, {
        method: 'PUT',
        headers,
      })
        .then((res) => res.json())
        .catch((error) => {
          swal(
            '',
            'Su alerta de suplantación ha fallado, intentalo nuevamente.',
            'error',
            {
              dangerMode: true,
            }
          ).then((value) => {
            window.location.reload();
          });
        })
        .then((response) => {
          swal('', 'Su alerta de suplantación ha sido exitosa.', 'success', {
            dangerMode: true,
          }).then((value) => {
            history.push('/informacion-cliente');
          });
        });
    }
  } else {
    throw new Error('Missing token');
  }
};
