var timeOut = 1000; //Segundos, cierre de sesión en 15 minutos de inactividad.
var counter = 0;
document.onclick = function () {
  counter = 0;
};
document.onmousemove = function () {
  counter = 0;
};
document.onkeypress = function () {
  counter = 0;
};
window.setInterval(CheckInactivityTime, 1000);

export function CheckInactivityTime() {
  counter++;
  var oPanel = document.getElementById("SecondsUntilExpire");
  if (oPanel) oPanel.innerHTML = timeOut - counter + "";
  if (counter >= timeOut) {
    window.location.replace("/logout");
  }
}
