import React from "react";
import FirstStep from "./FirstStep";
import SecondStep from "./SecondStep";
import ThirdStep from "./ThirdStep";

interface Props {
  stepIndex: number,
}

const GetStepContent: React.FC<Props> = ({stepIndex}) => {
  switch (stepIndex) {
    case 0:
      return <FirstStep />;
    case 1:
      return <SecondStep />;
    case 2:
      return <ThirdStep />;
    default:
      return <h2>Unknown stepIndex</h2>;
  }
}


export default GetStepContent;
