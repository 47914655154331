import { FormikHelpers } from "formik";

type Submit<T> = (values: T, actions: FormikHelpers<T>) => Promise<void> | void;

export const submitTrap = <T = any>(submit: Submit<T>) => {
  return async (values: T, actions: FormikHelpers<T>) => {
    try {
      actions.setSubmitting(true);
      await submit(values, actions);
    } catch {
    } finally {
      actions.setSubmitting(false);
    }
  };
};
