import React from "react";
import getAuthToken from "../helpers/getAuthToken";
import noop from "../helpers/noop";
import setAuthToken from "../helpers/setAuthToken";
import {
  UserContextAction,
  UserContextProps,
  UserContextStore,
} from "../types/UserContextTypes";

// tslint:disable-next-line: no-empty
const initialStore: UserContextStore = {
  account: {} as any,
};

export const UserContext = React.createContext<UserContextProps>({
  loggedIn: false,
  logout: noop,
  logout_without_token: noop,
  login: noop,
  store: initialStore,
  dispatch: noop,
});

UserContext.displayName = "UserContext";

const reducer = (state: UserContextStore, action: UserContextAction) => {
  return { ...state, [action.type]: action.value };
};

export const UserProvider: React.FC = ({ children }) => {
  const [loggedIn, setLoggedIn] = React.useState<boolean>(!!getAuthToken());
  const [store, dispatch] = React.useReducer(reducer, initialStore);

  const login = (token: string) => {
    console.assert(token.length > 0, "Missing token");
    setAuthToken(token);
    setLoggedIn(true);
  };

  const logout = () => {
    setAuthToken("");
    setLoggedIn(false);
  };

  const logout_without_token = () => {
    setLoggedIn(false);
  };

  return (
    <UserContext.Provider value={{ loggedIn, login, logout, logout_without_token, store, dispatch }}>
      {children}
    </UserContext.Provider>
  );
};

export default UserContext;
