import React, {useEffect, useState, useCallback} from "react";
import styled from "styled-components";
import Step3 from "../../../images/step3.png";
import UseInfoTransaction from "../../../service/UseInfoTransaction";
import useService from '../../../hooks/useService';
import { paramsURL } from '../../../helpers/paramsURL';
import { setLocalstorage } from "../../../helpers/setLocalStorage";


const Img = styled.img`
  height: 100px;
  width: 100px;
`;

const ThirdStep: React.FC = () => {

  const [, getInfoTransaction] = useService(UseInfoTransaction);
  const clientId = paramsURL("id");
  const [code, setcode] = useState(0);
  const [loading, setLoading] = useState(false);


  let getCode = useCallback( async() => {
    if(clientId !== null){
      let code = (await getInfoTransaction({clienteId:clientId})).payload.code
      setcode(code);
      setLocalstorage("code",code);
    }
    setLoading(false);
  },[clientId,getInfoTransaction])

  useEffect( () =>  {
    setLoading(true)
    getCode();
  }, [getCode]);
  
  return (
    !loading ? 
    <>
      <Img src={Step3} alt="Second step" />
      <h4>
        <p>La transacción ha sido exitosa y se ha confirmado con el número {code} </p>
        <b>Ingresa el número de tu factura, orden de compra o pedido para terminar el proceso</b>
      </h4>
    </>
    :
      <h4>
        <p>Cargando...</p>
      </h4>
  );


}

export default ThirdStep;
