import React from "react";
import styled from "styled-components";
import { Typography, Grid, Button } from "@material-ui/core";
import { Formik, Form } from "formik";
import ValidationSchema from "./ValidationSchema";
import useLogInPost from "../../../service/UseLogInPost";
import { history } from "../../../helpers/history";
import swal from "sweetalert";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { GeneralContainer } from "../../layout/UI/GeneralContainer";
import { FormContainer } from "../../layout/UI/FormContainer";
import { Article } from "../../layout/UI/Article";
import { InputField } from "../../layout/UI/InputField";
import { InputGroup } from "../../layout/UI/InputGroup";
import { Section } from "../../layout/UI/Section";

const InputPasswordStyled = styled.div`
  position: relative;
`;
const InputIconStyled = styled(InputField)`
  width: 255px;
`;

const Icon = styled.i`
  position: absolute;
  right: 1px;
  top: 0;
  height: 100%;
  width: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #515257;
`;

export interface FormValues {
  username: string;
  password: string;
}

const Login: React.FC = () => {
  const initialValues: FormValues = { username: "", password: "" };
  const [, postLogIn] = useLogInPost();
  const [showPassword, setShowPassword] = React.useState(false);

  return (
    <GeneralContainer>
      <FormContainer>
        <Article>
          <Typography component="h1" variant="h5">
            <big>Ingresa a tu cuenta</big>
          </Typography>
        </Article>
        <Formik
          initialValues={initialValues}
          validationSchema={ValidationSchema}
          onSubmit={async (values) => {
            const resultLogin = await postLogIn({
              ...values,
            });

            if (resultLogin.status === "error")
              return (
                <>
                  {swal("", "El usuario no existe.", "error", {
                    dangerMode: true,
                  }).then((value) => {
                    window.location.reload();
                  })}
                </>
              );
            else {
              history.push("/informacion-cliente");
            }
          }}
        >
          {({ errors, touched }) => (
            <Form>
              <Section>
                <Grid container spacing={2}>
                  <Grid item md={12} xs={12}>
                    <InputGroup>
                      <label>Usuario</label>
                      <InputField name="username" />
                      {errors.username && touched.username ? (
                        <div>
                          <small>{errors.username}</small>
                        </div>
                      ) : null}
                    </InputGroup>
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <InputGroup>
                      <label>Contraseña</label>
                      <InputPasswordStyled>
                        <InputIconStyled
                          type={showPassword ? "text" : "password"}
                          name="password"
                        />
                        <Icon
                          onClick={() =>
                            setShowPassword((prevValue) => !prevValue)
                          }
                        >
                          <FontAwesomeIcon
                            icon={showPassword ? faEyeSlash : faEye}
                          />
                        </Icon>
                      </InputPasswordStyled>
                      {errors.password && touched.password ? (
                        <div>
                          <small>{errors.password}</small>
                        </div>
                      ) : null}
                    </InputGroup>
                  </Grid>
                </Grid>
              </Section>

              <Grid container justify="center">
                <Grid item md={6} xs={12}>
                  <Button
                    type="submit"
                    variant="contained"
                    fullWidth
                    size="large"
                    color="secondary"
                  >
                    Ingresar
                  </Button>
                </Grid>
                <a href="www.google.com">Recuperar contraseña</a>
              </Grid>
            </Form>
          )}
        </Formik>
      </FormContainer>
    </GeneralContainer>
  );
};

export default Login;
