import styled from "styled-components";

export const Link = styled.a`
  text-decoration: none;
  height: 20px;
  color: #083863;
  padding-top: 1em;
  padding-bottom: 0.5em;
  cursor: pointer;
  font-size: 15px;
  :hover {
    color: #4b87bd;
  }
`;
