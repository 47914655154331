import serviceBuilder from "./serviceBuilder";

export interface Params {
    clienteId: string;
}

export interface Result {
  id: number;
  clienteId: string;
  status: string;
  code: string;
  bill: null
}

export const url = "transaction/:clienteId";

export default serviceBuilder<Params, Result>("get", {
  url,
  auth: true,
});
