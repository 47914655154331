import React from "react";
import { Redirect, RouteComponentProps } from "react-router-dom";
import UserContext from "../UserProvider";
import swal from "sweetalert";

const LogOut: React.FC<RouteComponentProps> = () => {
  const userContext = React.useContext(UserContext);

  React.useEffect(() => {
    userContext.logout();
    swal("", "Has cerrado sesión correctamente.", "success", {
      dangerMode: true,
    });
  }, [userContext]);

  return <Redirect to={"/"} />;
};

export default LogOut;
