import React from "react";
import { Typography, Grid, Button } from "@material-ui/core";
import { Formik, Form } from "formik";
import ValidationSchema from "./ValidationSchema";
import UseClientInfo from "../../../service/UseClientInfo";
import { history } from "../../../helpers/history";
import useService from "../../../hooks/useService";
import swal from "sweetalert";
import { GeneralContainer } from "../../layout/UI/GeneralContainer";
import { FormContainer } from "../../layout/UI/FormContainer";
import { Article } from "../../layout/UI/Article";
import { InputField } from "../../layout/UI/InputField";
import { InputGroup } from "../../layout/UI/InputGroup";
import { Section } from "../../layout/UI/Section";
import { CancelTransaction } from "../payment/paymentMessage";
import UseClientPhoto from "../../../service/UseClientPhoto";
import { submitTrap } from "../../../helpers/formHelpers";
import { setLocalstorage } from "../../../helpers/setLocalStorage";

export interface FormValues {
  id: string;
}

const ClientInformation: React.FC = () => {
  const initialValues: FormValues = { id: "" };
  const [, getClientInfo] = useService(UseClientInfo);
  const [, getClientPhoto] = useService(UseClientPhoto);

  return (
    <GeneralContainer>
      <FormContainer>
        <Article>
          <Typography component="h1" variant="h5">
            <big>Datos del cliente</big>
          </Typography>
        </Article>
        <Formik
          initialValues={initialValues}
          validationSchema={ValidationSchema}
          onSubmit={submitTrap(async (values) => {
            const resultClientInfo = await getClientInfo({
              id: values.id.trim(),
            });
            if (resultClientInfo.status === "loaded") {
              if (
                resultClientInfo.payload.merchantClient === true &&
                resultClientInfo.payload.clientStatus.id === 300
              ) {
                if (resultClientInfo.payload.transactionStatus === "OPEN") {
                  const resulClientPhoto = await getClientPhoto({
                    id: values.id.trim(),
                  });
                  if (resulClientPhoto.status === "loaded") {
                    setLocalstorage("clientName", resultClientInfo.payload.displayName);
                    history.push(`/cupo-cliente/?id=${values.id.trim()}`);
                  } else {
                    swal(
                      "",
                      "Para poder continuar con el pago, dile al cliente que se comunique con Juancho Te Presta al 018000413811.",
                      "warning",
                      {
                        dangerMode: true,
                      }
                    ).then((value) => {
                      window.location.reload();
                    });
                  }
                }
                if (
                  resultClientInfo.payload.transactionStatus ===
                  "REJECTION_BY_IMAGE_NOT_MATCH"
                ) {
                  swal(
                    "",
                    "Se ha cancelado esta transaccion previamente, por posible fraude. Por favor comuniquese con JuanChoTepresta.",
                    "error",
                    {
                      dangerMode: true,
                    }
                  ).then((value) => {
                    window.location.reload();
                  });
                }
                if (
                  resultClientInfo.payload.transactionStatus ===
                  "REJECTION_BY_ALERT_NOTIFICATION"
                ) {
                  CancelTransaction();
                }
                if (
                  resultClientInfo.payload.transactionStatus ===
                  "REJECTION_BY_TOO_MANY_OTP_VALIDATIONS"
                ) {
                  swal(
                    "",
                    "Ha sobrepasado el número de intentos de pago.",
                    "error",
                    {
                      dangerMode: true,
                    }
                  ).then((value) => {
                    window.location.reload();
                  });
                }
              }

              if (
                resultClientInfo.payload.merchantClient === true &&
                resultClientInfo.payload.clientStatus.id !== 300
              ) {
                swal("", "El cliente no tiene un crédito activo.", "error", {
                  dangerMode: true,
                }).then((value) => {
                  window.location.reload();
                });
              }
              if (
                resultClientInfo.payload.merchantClient === false &&
                resultClientInfo.payload.clientStatus.id === 300
              ) {
                swal(
                  "",
                  "Lo siento, el cliente no puede pagar con Juancho Te Paga. Si el cliente requiere más información pídele que se comunique con Juancho Te Presta al 018000413811.",
                  "error",
                  {
                    dangerMode: true,
                  }
                ).then((value) => {
                  window.location.reload();
                });
              }

              if (
                resultClientInfo.payload.merchantClient === false &&
                resultClientInfo.payload.clientStatus.id !== 300
              ) {
                swal("", "El cliente no tiene un crédito activo.", "error", {
                  dangerMode: true,
                }).then((value) => {
                  window.location.reload();
                });
              }
            } else return history.push("/cliente-no-registrado");
          })}
        >
          {({ errors, touched, isSubmitting }) => (
            <Form>
              <Section>
                <Grid container spacing={2}>
                  <Grid item md={12} xs={12}>
                    <InputGroup>
                      <label>Cédula del cliente</label>
                      <InputField name="id" />
                      {errors.id && touched.id ? (
                        <div>
                          <small>{errors.id}</small>
                        </div>
                      ) : null}
                    </InputGroup>
                  </Grid>
                  <Grid item md={12} xs={12}></Grid>
                </Grid>
              </Section>

              <Grid container justify="center">
                <Grid item md={6} xs={12}>
                  <Button
                    type="submit"
                    variant="contained"
                    fullWidth
                    size="large"
                    color="secondary"
                    disabled={isSubmitting}
                  >
                    Consultar
                  </Button>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </FormContainer>
    </GeneralContainer>
  );
};

export default ClientInformation;
