import styled from "styled-components";
export const InputGroup = styled.div`
  display: flex;
  flex-direction: column;
  height: 4em;
  label {
    color: #6f7173;
  }
  small {
    color: #ff1035;
  }
`;
