import React from "react";
import styled from "styled-components";
import colombiaFintech from "../../images/colombia-fintech.png";
import logo from "../../images/logo-juancho-te-presta.png";
import superintendencia from "../../images/sic.png";

const FooterStyled = styled.footer`
  position: relative;
  transform: translate(0px, 100%);
  background: #083863;
  padding-bottom: 2em;
  @media (min-width: 960px) {
    padding-bottom: 1em;
  }
`;
const ContentContainerStyled = styled.div`
  width: 100%;
  max-width: 85rem;
  margin: 0 auto;
  height: 33px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 3.75rem;
  bottom: 0;
  left: 0;
  @media (min-width: 960px) {
    height: 90px;
  }
`;

const Logo = styled.img`
  width: 6rem;
  height: 6rem;
  position: absolute;
  bottom: 2.5rem;

  @media (min-width: 960px) {
    width: 8rem;
    height: 8rem;
    bottom: 5rem;
  }
`;

const SuperIntendencia = styled.img`
  bottom: -0.998rem;
  position: absolute;
  left: 0.5rem;
  width: 98px;
  @media (min-width: 1000px) {
    width: auto;
  }
`;
const ColombiaFintech = styled.img`
  bottom: -0.25rem;
  position: absolute;
  right: 0.5rem;
  width: 4.5rem;
  @media (min-width: 960px) {
    width: 9rem;
  }
`;

const LinksFooter = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 5em;
  font-size: 12px;
  text-align: Center;
  a {
    text-decoration: none;
    color: #d6d6d6;
    &:hover {
      color: #fff;
    }
  }

  @media (min-width: 960px) {
    flex-direction: row;
    font-size: 14px;

    a {
      padding: 1em;
    }
  }
`;

const Footer: React.FC = () => {
  return (
    <FooterStyled>
      <ContentContainerStyled>
        <Logo src={logo} alt="Juancho te presta" />
        <LinksFooter>
          <a href=" ">Términos y condiciones</a>
          <a href=" ">Polítivas de privacidad</a>
          <a href=" ">Avisos Importantes</a>
        </LinksFooter>
        <SuperIntendencia
          src={superintendencia}
          alt="Vigilado Superintendencia Financiera de Colombia"
        />
        <ColombiaFintech src={colombiaFintech} alt="Colombia Fintech" />
      </ContentContainerStyled>
    </FooterStyled>
  );
};

export default Footer;
