import serviceBuilder from "./serviceBuilder";
import None from "../types/None";

export interface Result {
  createdAt: string;
  enabled: boolean;
  username: string;
  uuid: string;
  person: {
    createdAt: string;
    lastname: string;
    name: string;
    uuid: string;
    branchOffice: {
      name: string;
      merchant: {
        uuid: string;
        createdAt: string;
        name: string;
        fineractId: number;
      };
    };
  };
  roles: {
    name: string;
  }[];
}

export const url = "user/me";

export default serviceBuilder<None, Result>("get", {
  url,
  auth: true,
});
