/* eslint-disable no-mixed-operators */
import { faDollarSign } from '@fortawesome/free-solid-svg-icons';
import Button from '@material-ui/core/Button';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import Typography from '@material-ui/core/Typography';
import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import styled from 'styled-components';
import swal from 'sweetalert';
import { ReceiptIcon } from '../../../components/layout/UI/paymentIcon';
import { submitTrap } from '../../../helpers/formHelpers';
import getAuthToken from '../../../helpers/getAuthToken';
import { history } from '../../../helpers/history';
import moneyFormat from '../../../helpers/moneyFormat';
import { paramsURL } from '../../../helpers/paramsURL';
import timeout from '../../../helpers/timeout';
import useService from '../../../hooks/useService';
import logo from '../../../images/LogoJuanchoTePrestaNit.png';
import UseEventIdGet from '../../../service/UseEventIdGet';
import UseTransactionPost from '../../../service/UseTransactionPost';
import UseTransactionPut from '../../../service/UseTransactionPut';
import UseValidateAmount from '../../../service/UseValidateAmount';
import GeneralContent from '../../layout/UI/GeneralContent';
import { InputField } from '../../layout/UI/InputField';
import { InputGroup } from '../../layout/UI/InputGroup';
import InputNumber from '../../layout/UI/InputNumber';
import InputText from '../../layout/UI/InputText';
import { Link as LinkClientInfo } from '../../layout/UI/Link';
import { InformationContainer } from '../clientInformation/ClientQuota';
import GetStepContent from './getStepContent';
import {
  codeForwarding,
  ErrorBillTransaction,
  errorCode,
  expiredCode,
  incorrectCode,
  manyEntriesOtp,
  rejected,
} from './paymentMessage';
import { useStyles } from './useStyles';
import FirstStepValidationSchema from './validationSchema/FirstStepValidationSchema';
import SecondStepValidationSchema from './validationSchema/SecondStepValidationSchema';
import ThirdStepValidationSchema from './validationSchema/ThirdStepValidationSchema';
import { clearLocalStorage, getLocalstorage } from '../../../helpers/setLocalStorage';

export interface FirstStepValue {
  amount: string;
}
export interface SecondStepValue {
  phone: string;
}

export interface ThirdStepValue {
  bill: string;
}
const MainContainer = styled.div`
  
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 1rem 1rem 1rem;
  border: 2px solid rgb(231, 230, 230);
  border-radius: 20px;
`;

const StepperContainer = styled(InformationContainer)`
  padding: 5px;
  margin-top: 5px;
`;

const ChangeStyle = styled.div`
  .MuiSvgIcon-root {
    font-size: 2.5rem;
  }
`;
const Header = styled.div`
display: flex;
justify-content: space-between;
align-items: center;`

const IconHeader = styled.div`
display: flex;
`
const InfoHeader = styled.div`
display: flex;
flex-direction: column;
> h6 {
  margin: 0;
  text-align: right;
  font-weight: 400;
  font-size: 12px;
  color:rgb(118, 113,113);
}
> h5 {
  margin: 0;
  text-align: right;
  font-weight: 400;
  font-size: 12px;
}
> h4 {
  margin: 0;
  text-align: right;
  font-weight: 400;
  font-size: 20px;
}
`
const InfoContainer = styled.div`
  background-color: rgb(247, 247,247);
  border-radius: 8px;
  padding:1.3rem 0.7rem 2.3rem 0.7rem;
  > h5 {
    margin: 4px 0px;
    text-align: left;
    font-weight: 400;
    font-size: 12px;
  }
`
const ValuesInfo = styled.section`
margin-top:1.5rem;
display: flex;
justify-content: space-between;
align-items: center;
> h6 {
  margin: 0;
  text-align: left;
  font-weight: 600;
  font-size: 14px;
}
`
const Explanatory = styled.section`
margin-top:4.5rem;
display: flex;
justify-content: center;
align-items: center;
border: 2px dotted rgb(231, 230, 230);
border-radius: 18px;
padding:2.3rem 0.7rem;
width:300px;
> h6 {
  margin: 0;
  font-weight: 400;
  font-size: 14px;
}
@media (min-width: 700px) {
  margin-top:3.5rem;
  width:400px;
  }
`
const ValuesSeccion = styled.section`
disply:flex;
padding-right:8px;
background-color:rgb(231, 230,230);
border-radius: 7px;
width: 215px;
text-align: right;
align-items: center;

> h5 {
  margin: 4px;
  text-align: right;
  font-size: 18px;
  font-weight: 500;
}
`

const Foot = styled.div`
display: flex;
margin:1rem 0;
justify-content: center;
position:relative;
width:300px;
@media (min-width: 700px) {
width:400px;
}
`
const MineFoot = styled.div`
display: grid;
grid-template-columns: 1fr 1fr 1fr;
grid-gap: 3px; 
justify-content: center;
position:absolute;
top:10px;

`

const SectionFoot = styled.section`
padding:0.8rem;
display: flex;
justify-content: center;
background-color:rgb(242, 242,242);
flex-direction: column;
align-items:center;
border-radius: 8px;
> h6 {
  margin: 0;
  font-weight: 600;
  font-size: 10px;
}
> h5 {
  margin: 0;
  font-weight: 400;
  font-size: 12px;
}
`

function getSteps() {
  return ['', '', ''];
}

export const InputFielAmount = styled(InputNumber)``;
export const InputFieldPhone = styled(InputField)``;
export const InputFieldBill = styled(InputField)``;

function money(value: any) {
  return moneyFormat(value, true, false);
}

const HorizontalLabelPositionBelowStepper: React.FC = () => {

  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const steps = getSteps();
  const FirstInitIalValues: FirstStepValue = { amount: '' };
  const SecondInitIalValues: SecondStepValue = { phone: '' };
  const ThirdInitIalValues: ThirdStepValue = { bill: '' };
  const [, getValidateAmount] = useService(UseValidateAmount);
  const [event, getEventId] = useService(UseEventIdGet);
  const [, postTransaction] = useService(UseTransactionPost);
  const [, putTransaction] = useService(UseTransactionPut);

  const amount = paramsURL('amount');
  const clientId = paramsURL('id');

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const becomeData = (data: string) => {
    const fecha = new Date(data);
    const day = fecha.getDate();
    const month = fecha.toLocaleString('default', { month: 'long' });
    const year = fecha.getFullYear();
    const fechaFormateada = day + " " + month + " " + year;
    return (fechaFormateada)
  }
  const opciones = { useGrouping: true, minimumFractionDigits: 0 };
  const postOtp = async (value: any, amount?: number) => {
    const headers = new Headers();
    headers.append('Content-Type', 'application/json; charset=utf-8');
    const token = getAuthToken();
    if (token) {
      if (clientId !== null) {
        headers.append('Authorization', `Bearer ${token}`);
        const API_URL: string =
          process.env.REACT_APP_CMS === undefined
            ? `https://jtpg.testing.vertical.com.co/jtpg/api/client/${clientId}/otp`
            : `https://${process.env.REACT_APP_CMS}/jtpg/api/client/${clientId}/otp`;
        const responseResult = await fetch(API_URL, {
          method: 'POST',
          body: JSON.stringify({ amount: amount }),
          headers,
        });
        const content = await responseResult.json();

        if (responseResult.ok) {
          const event = content.eventId;
          while (true) {
            let {
              payload: { status, exception },
            } = await getEventId({
              eventId: event,
            });
            if (status === 'OK') return value();
            await timeout(1200);
            if (
              status === 'ERROR' &&
              exception === 'Client have too many retries'
            )
              return manyEntriesOtp();
          }
        }
        if (responseResult.status === 429) {
          manyEntriesOtp();
        }
      }
    } else {
      throw new Error('Missing token');
    }
  };

  return (
    <GeneralContent>
      <div className={classes.root}>
        <ChangeStyle>
          <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </ChangeStyle>
        <GeneralContent>
          {activeStep === steps.length ? (
            <MainContainer>
              <Typography>
                <Header>
                  <IconHeader>
                    <ReceiptIcon src={logo} alt="logo" />

                  </IconHeader>
                  <InfoHeader>
                    <h5> FACTURA N°:</h5>
                    <h4>{getLocalstorage('bill')}</h4>
                    <h6>{event.status === 'loaded' ? becomeData(event.payload.modifiedAt) : ""}</h6>
                  </InfoHeader>
                </Header>
                <InfoContainer>
                  <h5>{getLocalstorage("clientName")}</h5>
                  <h5>{event.status === 'loaded' ? Number(event.payload.payload.clientId).toLocaleString(undefined, opciones) : ""}</h5>
                  <h5>{`ID Transacción: ${getLocalstorage("code")}`}</h5>
                  <ValuesInfo>
                    <h6>Valor compra</h6>
                    <ValuesSeccion><h5>{money(getLocalstorage('amount'))}</h5></ValuesSeccion>
                  </ValuesInfo>
                  <Foot>
                    <MineFoot>
                      <SectionFoot>
                        <h5>{getLocalstorage("merchantName")}</h5>
                        <h6>Comercio</h6>
                      </SectionFoot>
                      <SectionFoot>
                        <h5>{getLocalstorage("branchOfficeName")}</h5>
                        <h6>Sucursal</h6>
                      </SectionFoot>
                      <SectionFoot>
                        <h5>{getLocalstorage("personName")}</h5>
                        <h6>Asesor</h6>
                      </SectionFoot>
                    </MineFoot>
                  </Foot>
                </InfoContainer>
                <Explanatory>
                  <h6>El saldo restante de tu préstamo será transferido a tu cuenta bancaria. </h6>
                </Explanatory>
              </Typography>
            </MainContainer>
          ) : (
            <StepperContainer>
              <div>
                <Typography className={classes.instructions} component={'span'}>
                  <GetStepContent stepIndex={activeStep} />
                </Typography>
                <div>
                  {(activeStep === 0 && (
                    <Formik
                      initialValues={FirstInitIalValues}
                      validationSchema={FirstStepValidationSchema}
                      onSubmit={submitTrap(async (values) => {
                        if (typeof Storage !== 'undefined') {
                          localStorage.setItem('amount', `${values.amount}`);
                        }

                        if (clientId !== null) {

                          const resultClientInfo = await getValidateAmount({
                            id: clientId,
                            amount: values.amount,
                          });

                          if (resultClientInfo.status === 'loaded') {
                            await postOtp(handleNext, parseInt(values.amount, 10));
                          } else {
                            await swal(
                              `El cliente no puede pagar toda la compra con Juancho Te Presta. El valor disponible es de: ${money(
                                resultClientInfo.error.payload.amount
                              )} `,
                              {
                                dangerMode: true,
                                icon: 'warning',
                                buttons: [
                                  'Cancelar la compra',
                                  'Pagar un valor diferente',
                                ],
                              }
                            ).then((willDelete) => {
                              if (willDelete) {
                                window.location.replace(
                                  `/pago/?id=${clientId}&amount=${resultClientInfo.error.payload.amount}`
                                );
                              } else {
                                history.push('/informacion-cliente');
                              }
                            });
                          }
                        }
                      })}
                    >
                      {({ errors, touched, isSubmitting }) => (
                        <Form>
                          <InputGroup>
                            <InputNumber name="amount" icon={faDollarSign} />
                            {errors.amount && touched.amount ? (
                              <div>
                                <small>{errors.amount}</small>
                              </div>
                            ) : null}
                          </InputGroup>
                          <div>
                            <Button
                              disabled={activeStep === 0 || isSubmitting}
                              variant="contained"
                              color="primary"
                              onClick={handleBack}
                              className={classes.backButton}
                            >
                              Regresar
                            </Button>

                            <Button
                              type="submit"
                              variant="contained"
                              color="primary"
                              className={classes.backButton}
                              disabled={isSubmitting}
                            >
                              {activeStep === steps.length - 1
                                ? 'Terminar'
                                : 'Continuar'}
                            </Button>
                          </div>
                          {amount !== null && (
                            <p className="red font">
                              Valor disponible del cliente: {money(amount)}
                            </p>
                          )}
                        </Form>
                      )}
                    </Formik>
                  )) ||
                    (activeStep === 1 && (
                      <Formik
                        initialValues={SecondInitIalValues}
                        validationSchema={SecondStepValidationSchema}
                        onSubmit={submitTrap(async (values) => {
                          const headers = new Headers();
                          headers.append(
                            'Content-Type',
                            'application/json; charset=utf-8'
                          );
                          const token = getAuthToken();
                          if (token) {
                            if (clientId !== null) {
                              headers.append(
                                'Authorization',
                                `Bearer ${token}`
                              );
                              const API_URL: string =
                                process.env.REACT_APP_CMS === undefined
                                  ? `https://jtpg.testing.vertical.com.co/jtpg/api/client/${clientId}/otp/${values.phone}`
                                  : `https://${process.env.REACT_APP_CMS}/jtpg/api/client/${clientId}/otp/${values.phone}`;

                              const responseResultCode = await fetch(
                                API_URL,
                                {
                                  method: 'POST',
                                  headers,
                                }
                              );

                              if (responseResultCode.ok) {
                                const contentCode = await responseResultCode.json();
                                if (contentCode.correct === true) {
                                  if (typeof Storage !== 'undefined') {
                                    const amountTransaction = localStorage.getItem(
                                      'amount'
                                    );
                                    const resultTransaction = await postTransaction(
                                      {
                                        amount: amountTransaction,
                                        clientId: clientId,
                                      }
                                    );
                                    if (
                                      resultTransaction.status === 'loaded'
                                    ) {
                                      const transactionEventId =
                                        resultTransaction.payload.eventId;
                                      while (true) {
                                        let {
                                          payload: { status },
                                        } = await getEventId({
                                          eventId: transactionEventId,
                                        });
                                        if (status === 'OK')
                                          return handleNext();
                                        await timeout(1200);
                                      }
                                    }
                                  }
                                }
                                if (contentCode.correct === false) {
                                  incorrectCode();
                                }
                              } else if (responseResultCode.status === 400) {
                                expiredCode();
                              } else if (responseResultCode.status === 429) {
                                manyEntriesOtp();
                              } else if (responseResultCode.status === 409) {
                                rejected();
                              } else {
                                return errorCode();
                              }
                            }
                          } else {
                            throw new Error('Missing token');
                          }
                        })}
                      >
                        {({ errors, touched, isSubmitting }) => (
                          <Form>
                            <InputGroup>
                              <InputFieldPhone name="phone" />
                              {errors.phone && touched.phone ? (
                                <div>
                                  <small>{errors.phone}</small>
                                </div>
                              ) : null}
                            </InputGroup>
                            <div>
                              <Button
                                variant="contained"
                                color="primary"
                                onClick={handleBack}
                                className={classes.backButton}
                                disabled={isSubmitting}
                              >
                                Regresar
                              </Button>
                              <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                className={classes.backButton}
                                disabled={isSubmitting}
                              >
                                {activeStep === steps.length - 1
                                  ? 'Terminar'
                                  : 'Continuar'}
                              </Button>
                            </div>
                            <Button
                              disableRipple
                              onClick={() => {
                                let amount = localStorage.getItem('amount');
                                if (amount !== null) {
                                  postOtp(codeForwarding, parseInt(amount, 10));
                                }
                              }}
                              color="secondary"
                              disabled={isSubmitting}
                            >
                              Reenviar código
                            </Button>
                          </Form>
                        )}
                      </Formik>
                    )) ||
                    (activeStep === 2 && (
                      <Formik
                        initialValues={ThirdInitIalValues}
                        validationSchema={ThirdStepValidationSchema}
                        onSubmit={submitTrap(async (values) => {
                          if (typeof Storage !== 'undefined') {
                            localStorage.setItem('bill', `${values.bill}`);
                          }
                          if (clientId !== null) {
                            const resultTransactionPut = await putTransaction(
                              {
                                clientId: clientId,
                                billNumber: values.bill,
                              }
                            );
                            if (resultTransactionPut.status === 'loaded') {
                              const billEventId =
                                resultTransactionPut.payload.eventId;
                              while (true) {
                                let {
                                  payload: { status },
                                } = await getEventId({
                                  eventId: billEventId,
                                });
                                if (status === 'OK') return handleNext();
                                await timeout(1200);
                                if (status === 'ERROR')
                                  return ErrorBillTransaction();
                              }
                            }
                          }
                        })}
                      >
                        {({ errors, touched, isSubmitting }) => (
                          <Form>
                            <InputGroup>
                              <InputText name="bill" regex={/[a-z0-9]+/gi} />
                              {errors.bill && touched.bill ? (
                                <div>
                                  <small>{errors.bill}</small>
                                </div>
                              ) : null}
                            </InputGroup>
                            <div>
                              <Button
                                variant="contained"
                                color="primary"
                                onClick={handleBack}
                                className={classes.backButton}
                                disabled={isSubmitting}
                              >
                                Regresar
                              </Button>
                              <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                className={classes.backButton}
                                disabled={isSubmitting}
                              >
                                {activeStep === steps.length - 1
                                  ? 'Terminar'
                                  : 'Continuar'}
                              </Button>
                            </div>
                          </Form>
                        )}
                      </Formik>
                    ))}
                </div>
              </div>
            </StepperContainer>
          )}

          <LinkClientInfo onClick={() => {
            clearLocalStorage();
            history.push("/informacion-cliente");
          }}>
            {' '}
            Consultar otro cliente
          </LinkClientInfo>
        </GeneralContent>
      </div>
    </GeneralContent>
  );
}

export default HorizontalLabelPositionBelowStepper;
