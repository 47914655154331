import serviceBuilder from "./serviceBuilder";

export interface Params {
  token: string;
}

export const url = "transaction/status";

export default serviceBuilder<Params>("post", {
  url,
  auth: false,
});
