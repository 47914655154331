/* eslint-disable no-unreachable */

import { TokenAttrs } from "./tokenHelper";
import { decode } from "jsonwebtoken";
import { CheckInactivityTime } from "./Inactivity";

const getAuthToken = () => {
  let token: string;
  if (localStorage) {
    token = localStorage.getItem("token") || "";
    if (token) {
      try {

        const timestamp = Math.round(new Date().getTime() / 1000);
        const { exp } = decode(token) as TokenAttrs;
        if (exp !== null) {
          CheckInactivityTime();
          if (timestamp >= exp) {
            window.location.replace("/logout");
            //timeout
          }
        }
        
      } catch (e) {
        window.location.replace("/logout");
      }
    }
  } else {
    token = document.cookie.replace(
      /(?:(?:^|.*;\s*)token\s*=\s*([^;]*).*$)|^.*$/,
      "$1"
    );
  }
  return token.trim();
};

export default getAuthToken;
