import styled from "styled-components";
import { Field } from "formik";

export const InputField = styled(Field)`
  border: none;
  border-bottom: 1px solid #d6d6d6;
  padding: 0.5em;
  font-size: 15px;
  color: #5f6061;
`;
