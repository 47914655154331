import serviceBuilder from "./serviceBuilder";

export interface Params {
  id: string;
}

export interface Result {
  image: string;
}

export const url = "client/:id/image";

export default serviceBuilder<Params, Result>("get", {
  url,
  auth: true,
});
