import React from "react";
import styled from "styled-components";
import Step2 from "../../../images/step2.png";

const Img = styled.img`
  height: 100px;
  width: 100px;
`;

const SecondStep: React.FC = () => {
  return (
    <>
      <Img src={Step2} alt="Second step" />
      <h4>
        <b>Ingresa el código que le llegó al cliente en el celular.</b>
      </h4>
    </>
  );
};

export default SecondStep;
