import React from "react";
import styled from "styled-components";
import GeneralContent from "../../layout/UI/GeneralContent";
import logo from "../../../images/not-found.png";
import { Button, Grid } from "@material-ui/core";
import { InformationContainer } from "./ClientQuota";

const Img = styled.img`
  display: block;
  height: 100px;
  width: 100px;
`;

const ClientNotFound: React.FC = () => {
  return (
    <GeneralContent>
      <InformationContainer>
        <Img src={logo} alt="Juancho te presta" />
        <p>Este cliente NO se encuentra en la base de datos.</p>
        <p>Por favor dile que se registre y solicite su crédito.</p>

        <Grid container justify="center">
          <Grid item md={8} xs={8}>
            <Button
              type="submit"
              variant="contained"
              fullWidth
              size="large"
              color="secondary"
              href="/informacion-cliente"
            >
              Realizar otra consulta
            </Button>
          </Grid>
        </Grid>
      </InformationContainer>
    </GeneralContent>
  );
};

export default ClientNotFound;
