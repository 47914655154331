import serviceBuilder from "./serviceBuilder";

export interface Params {
  clientId: string;
  amount: any;
}

export interface Result {
  eventId: string;
}

export const url = "transaction";

export default serviceBuilder<Params, Result>("post", {
  url,
  auth: true,
});
