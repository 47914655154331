import React from "react";
import styled from "styled-components";
import Step1 from "../../../images/step1.png";

const Img = styled.img`
  height: 100px;
  width: 100px;
`;

const FirstStep: React.FC = () => {
  return (
    <>
      <Img src={Step1} alt="First step" />
      <h4>
        <b>Ingresa el valor de la compra del cliente.</b>
      </h4>
    </>
  );
};

export default FirstStep;
