import serviceBuilder from "./serviceBuilder";

export interface Params {
  eventId: string;
}

export interface Result {
  id: string;
  type: string;
  channel: string;
  userId: string;
  ts: number;
  payload: {
    clientId: string;
    amount: string;
    userUuid: string;
  };
  createdAt: string;
  status: string;
  exception: any;
  modifiedAt: string;
}

export const url = "event/:eventId";

export default serviceBuilder<Params, Result>("get", {
  url,
  auth: true,
});
