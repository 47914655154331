import { useCallback, useState } from "react";
import { ServiceMethod } from "../service/serviceBuilder";
import { Service } from "../types/Service";
import useIsMounted from "./useIsMounted";

function useService<P, R, E>(
  service: ServiceMethod<P, R, E>
): [Service<R, E>, ServiceMethod<P, R, E>] {
  const [state, setState] = useState<Service<R, E>>({ status: "init" });
  const isMounted = useIsMounted();

  const request = useCallback(
    async (params?: P) => {
      if (!isMounted) throw new Error("ABORT");

      setState({ status: "loading" });
      const result = await service(params);

      if (!isMounted) throw new Error("ABORT");

      setState(result);
      return result;
    },
    [isMounted, service]
  );

  return [state, request];
}

export default useService;
