import React from "react";
import { Toolbar } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import styled from "styled-components";
import MoneyIcon from "../../images/MoneyIcon.png";
import Logo from "../../images/logo-juancho-te-presta.png";
import UserContext from "../UserProvider";

const ButtonContainer = styled.div`
  margin-left: 12px;
  border-radius: 6px;
  border: 0.5px solid white;
  height: 30px;
  padding-bottom: 5px;
  margin: 10px;
`;

const Img = styled.img`
  display: block;
  margin-top: 7px;
  height: 30px;
  width: 30px;
  @media (min-width: 850px) {
    margin-top: 10px;
    height: 35px;
    width: 35px;
    margin-right: 7px;
  }
`;

const EndSecction = styled.section`
  width: 50%;
  display: flex;
  flex-direction: row-reverse;
`;

const StartSection = styled.section`
  width: 200%;
  display: flex;
  flex-direction: row;

  h1 {
    font-size: 15px;
    margin-left: 10px;
    margin-top: 16px;
    @media (min-width: 850px) {
      margin-top: 20px;
      font-size: 20px;
    }
  }
`;

const LogoImg = styled.img`
  display: none;

  @media (min-width: 850px) {
    display: block;
    height: 50px;
    width: 50px;
  }
`;

const BarSession: React.FC = () => {
  const userContext = React.useContext(UserContext);
  return (
    <div>
      <Toolbar>
        <StartSection>
          <Img src={MoneyIcon} alt="Juancho Te Paga" />
          <h1>Juancho Te Paga</h1>
        </StartSection>

        <EndSecction>
          {userContext.loggedIn && (
            <ButtonContainer>
              <Button color="inherit" href="/logout">
                Salir
              </Button>
            </ButtonContainer>
          )}
          <LogoImg src={Logo} alt="Juancho te presta" />
        </EndSecction>
      </Toolbar>
    </div>
  );
};

export default BarSession;
