import swal from "sweetalert";
import { history } from "../../../helpers/history";

export const manyEntriesOtp = () => {
  swal(
    "",
    "Has sobrepasado el número de intentos, ingrese más tarde.",
    "error",
    {
      dangerMode: true,
    }
  ).then((value) => {
    history.push("/informacion-cliente");
  });
};

export const rejected = () => {
  swal(
    "",
    "La transaccion fue rechazada.",
    "error",
    {
      dangerMode: true,
    }
  ).then((value) => {
    history.push("/informacion-cliente");
  });
};

export const incorrectCode = () => {
  swal("", "Código incorrecto, intente nuevamente.", "error", {
    dangerMode: true,
  });
};

export const CancelTransaction = () => {
  swal(
    "",
    " Se ha cancelado esta transaccion previamente, por solicitud del cliente desde whatsapp. Por favor comuniquese con JuanChoTepresta.",
    "error",
    {
      dangerMode: true,
    }
  ).then((value) => {
    history.push("/informacion-cliente");
  });
};

export const ErrorBillTransaction = () => {
  swal("", "Ha ocurrido un error enviando el número de tu factura.", "error", {
    dangerMode: true,
  }).then((value) => {
    history.push("/informacion-cliente");
  });
};

export const codeForwarding = () => {
  swal("", "Se ha reenviado el código correctamente.", "success", {
    dangerMode: true,
  });
};

export const expiredCode = () => {
  swal(
    "",
    "El código enviado al whatsapp ha caducado o está inactivo. Solicita un nuevo código, desde la opción Reenviar código.",
    "error",
    {
      dangerMode: true,
    }
  );
};

export const errorCode = () => {
  swal("", "Ha ocurrido un error, ingrese nuevamente.", "error", {
    dangerMode: true,
  });
};
