import serviceBuilder from "./serviceBuilder";

export interface Params {
  id: string;
}

export interface Result {
  fineractId: number;
  clientStatus: {
    id: number;
    code: string;
    name: string;
  };
  firstName: string;
  lastName: string;
  displayName: string;
  id: string;
  merchantClient: boolean;
  transactionStatus: string;
}

export const url = "client/:id";

export default serviceBuilder<Params, Result>("get", {
  url,
  auth: true,
});
