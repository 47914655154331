import serviceBuilder from "./serviceBuilder";

export interface Params {
  clientId: string;
  billNumber: any;
}

export interface Result {
  eventId: string;
}

export const url = "transaction";

export default serviceBuilder<Params, Result>("put", {
  url,
  auth: true,
});
