import styled from "styled-components";

export const paymentIcon = styled.img`
  display: flex;
  height: 30px;
  width: 30px;
  padding-left: 45%;
  padding-bottom: 10%;

  @media (min-width: 850px) {
    padding-left: 37%;
    height: 70px;
    width: 70px;
  }
`;
export const ReceiptIcon = styled.img`
  display: flex;
  height: 100px;
  width: 100px;
  padding: 13px;

  @media (min-width: 850px) {
    height: 120px;
    width: 120px;
  }
`;
