import React from "react";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import theme from "./theme/theme";
import AppNavbar from "./components/layout/AppNavbar";
import { BrowserRouter, Switch, Route, Router } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Login from "./components/views/login/Login";
import Footer from "./components/layout/Footer";
import ClientInformation from "./components/views/clientInformation/ClientInformation";
import ClientNotFound from "./components/views/clientInformation/ClientNotFound";
import ClientQuota from "./components/views/clientInformation/ClientQuota";
import HorizontalNonLinearStepper from "./components/views/payment/PaymentView";
import { history } from "../src/helpers/history";
import PrivateRoute from "./helpers/PrivateRoute";
import { UserProvider } from "./components/UserProvider";
import LogOut from "./components/views/LogOut";
import NotFound from "./components/views/NotFound";
import CancelPayment from "./components/views/CancelPaymentView";

const App: React.FC = () => {
  return (
    <BrowserRouter>
      <MuiThemeProvider theme={theme}>
        <UserProvider>
          <AppNavbar />
          <Grid container>
            <Router history={history}>
              <Switch>
                <Route path="/" exact component={Login} />
                <PrivateRoute
                  path="/informacion-cliente"
                  exact
                  component={ClientInformation}
                />
                <PrivateRoute
                  path="/cliente-no-registrado"
                  exact
                  component={ClientNotFound}
                />
                <PrivateRoute
                  path="/cupo-cliente"
                  exact
                  component={ClientQuota}
                />
                <PrivateRoute
                  path="/pago"
                  exact
                  component={HorizontalNonLinearStepper}
                />
                <Route
                  path="/cancelar-pago/:token"
                  exact
                  component={CancelPayment}
                />
                <Route path="/logout" exact component={LogOut} />
                <Route component={NotFound} />
              </Switch>
            </Router>
          </Grid>
          <Footer />
        </UserProvider>
      </MuiThemeProvider>
    </BrowserRouter>
  );
};

export default App;
