import React from "react";
import AppBar from "@material-ui/core/AppBar";
import BarSession from "./BarSession";

const AppNavbar: React.FC = () => (
  <div>
    <AppBar position="static">
      <BarSession />
    </AppBar>
  </div>
);

export default AppNavbar;
