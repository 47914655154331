import React, { useContext } from "react";
import { Redirect, Route, RouteProps } from "react-router-dom";
import UserContext from "../components/UserProvider";

export interface Props extends RouteProps {
  component: React.FC<any>;
}

const PrivateRoute: React.FC<Props> = ({ component: Component, ...route }) => {
  const userContext = useContext(UserContext);

  return (
    <Route
      path={route.path}
      render={(props) => {
        const { token } = props.match.params;

        if (token) {
          userContext.login(token);
        }

        return userContext.loggedIn || token ? (
          <Component {...props} />
        ) : (
          <Redirect to="/" />
        );
      }}
    />
  );
};

export default PrivateRoute;
