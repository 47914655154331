import React, { useContext, useEffect, useMemo } from "react";
import UserContext from "../components/UserProvider";
import { Service } from "../types/Service";
import serviceBuilder, { ServiceMethod } from "./serviceBuilder";

export interface Params {
  username: string;
  password: string;
}

export interface Result {
  token: string;
}

export const url = "login";

const useLogInPost = (): [Service<Result>, ServiceMethod<Params, Result>] => {
  const [state, setState] = React.useState<Service<Result>>({
    status: "init",
  });

  const userContext = useContext(UserContext);

  const post = useMemo(
    () =>
      serviceBuilder<Params, Result>("post", {
        url,
        auth: false,
        set: setState,
      }),
    [setState]
  );

  useEffect(() => {
    if (state.status === "loaded") {
      if (state.payload && state.payload.token) {
        userContext.login(state.payload.token);
      } else {
        setState({ status: "error", payload: "" } as any);
      }
    }
  }, [state, userContext]);

  return [state, post];
};

export default useLogInPost;
