import serviceBuilder from "./serviceBuilder";

export interface Params {
  id: string;
  amount: string;
}

export interface Result {
  amount: number;
}

export const url =
  "transaction?amount=:amount&clientId=:id&command=verifyAmount";

export default serviceBuilder<Params, Result>("get", {
  url,
  auth: true,
});
